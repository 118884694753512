import React, { useEffect, useState } from "react"
import queryString from "query-string"
import axios from "axios"
import { Link } from "gatsby"
import Layout from "../components/layout"

import Logo from "../images/logo.svg"

const NewsletterPage = ({ location }) => {
  const [auth, setAuth] = useState(null)
  const [fail, setFail] = useState(false)
  useEffect(() => {
    const id = queryString.parse(location.search)
    console.log(id.id)
    const ID = id.id
    setAuth(ID)

    if (ID) {
      axios
        .post("https://helpyourlocalstore-newsletter.herokuapp.com/confirm", {
          id: ID,
        })
        .then(response => {
          // handle success
          console.log(response)
        })
        .catch(err => {
          console.log(err)
          setFail(true)
        })
    }
  }, [location.search])

  return (
    <div>
      <Layout>
        <div className="newsletter-page">
          <img src={Logo}></img>
        </div>
        {auth ? (
          <div className="newsletter-page__content">
            {fail ? (
              <div>
                <p>etwas ist schiefgelaufen...</p>
                <Link className="newsletter-page__link" to="/">
                  Zur Startseite
                </Link>
              </div>
            ) : (
              <div>
                <p>Vielen Dank für das Einschreiben in den Newsletter!</p>
                <Link className="newsletter-page__link" to="/">
                  Zur Startseite
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="roller-holder">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </Layout>
    </div>
  )
}
export default NewsletterPage
